<template>
	<div id="bots">
        <div class="tabela-bot">
            <div class="col-12 novo-bot">
                <v-btn class="primary-button" raised @click="createBotDialog" :disabled="$store.getters.company_id == null ? true : false">
                    <i class="mr-2 fas fa-plus"></i> Cadastrar Bot
                </v-btn>
            </div>
            <CustomTable 
                v-if="headers != ''"
                :action="'getBots'"
                :getter="$store.getters.bots"
                :headers="headers"
                :search="true"
                :title="name"
                :icone="'fas fa-robot'"
                :pagination="true"
                :filters="filtros"
                ref="tabela"
            >
                <template v-slot:acoes="{ item }">
                    <v-btn class="primary-button" raised small @click="editar(item.id)" :disabled="$store.getters.company_id == null ? true : false">
                        Editar
                    </v-btn>
                </template>
            </CustomTable>
            <v-dialog v-model="dialog" persistent max-width="1200px">
                <v-card>
                    <v-card-title>
                        <span v-if="dados.id" class="headline">Editar Bot</span>
                        <span v-else class="headline">Novo Bot</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-stepper v-model="step">
                                <v-stepper-header>
                                    <v-stepper-step
                                        :editable="dados.id ? true : false"
                                        :complete="step > 1"
                                        step="1"
                                    >
                                        Dados Bot
                                    </v-stepper-step>
                            
                                    <v-divider></v-divider>
                            
                                    <v-stepper-step
                                        :editable="dados.id ? true : false"
                                        :complete="step > 2"
                                        step="2"
                                    >
                                        Dados Obrigatórios
                                    </v-stepper-step>
                            
                                    <v-divider></v-divider>
                            
                                    <v-stepper-step
                                        :editable="dados.id ? true : false"
                                        :complete="step > 3"
                                        step="3"
                                    >
                                        Ações
                                    </v-stepper-step>
                            
                                    <v-divider></v-divider>
                            
                                    <v-stepper-step
                                        :editable="dados.id ? true : false" 
                                        step="4"
                                    >
                                        Dados Menus
                                    </v-stepper-step>
                                </v-stepper-header>
                                
                                <v-stepper-items>
                                    <v-stepper-content step="1">
                                        <v-form ref="form_bot">
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="row">
                                                        <div class="col-6">
                                                            <v-text-field 
                                                                :rules="[v => !!v || 'Campo Nome obrigatório']" 
                                                                v-model="dados.name" 
                                                                label="Nome *" 
                                                                placeholder="Nome *" 
                                                                hide-details
                                                                outlined
                                                            />
                                                        </div>
                                                        <div class="col-6">
                                                            <v-select 
                                                                :rules="[v => v !== '' || 'Campo Homologação obrigatório']" 
                                                                :items="boolean"
                                                                item-text="text"
                                                                item-value="value" 
                                                                v-model="dados.settings.homologation" 
                                                                label="Homologação *" 
                                                                placeholder="Homologação *" 
                                                                background-color="white"
                                                                hide-details
                                                                outlined
                                                            />
                                                        </div>
                                                        <div class="col-6">
                                                            <v-select 
                                                                :rules="[v => v !== '' || 'Campo Perguntar Nome obrigatório']" 
                                                                :items="boolean"
                                                                item-text="text"
                                                                item-value="value" 
                                                                v-model="dados.settings.ask_name" 
                                                                label="Perguntar Nome *" 
                                                                placeholder="Perguntar Nome *" 
                                                                background-color="white"
                                                                hide-details
                                                                outlined
                                                                @change="confirmName()"
                                                            />
                                                        </div>
                                                        <div class="col-6">
                                                            <v-select 
                                                                :rules="[v => v !== '' || 'Campo Confirmar Nome obrigatório']" 
                                                                :items="boolean"
                                                                item-text="text"
                                                                item-value="value" 
                                                                :disabled="dados.settings.ask_name == false"
                                                                v-model="dados.settings.confirm_name" 
                                                                label="Confirmar Nome *" 
                                                                placeholder="Confirmar Nome *" 
                                                                background-color="white"
                                                                hide-details
                                                                outlined
                                                            />
                                                        </div>
                                                        <div class="col-6">
                                                            <v-select 
                                                                :rules="[v => v !== '' || 'Campo Perguntar Rede Sociais obrigatório']" 
                                                                :items="boolean"
                                                                item-text="text"
                                                                item-value="value" 
                                                                v-model="dados.settings.ask_social_networks" 
                                                                label="Perguntar Rede Sociais *" 
                                                                placeholder="Perguntar Rede Sociais *" 
                                                                background-color="white"
                                                                hide-details
                                                                outlined
                                                            />
                                                        </div>

                                                        <div class="col-md-6 col-12">
                                                            <v-select 
                                                                :rules="[v => !!v || 'Campo Rede Sociais obrigatório']" 
                                                                :items="social_networks" 
                                                                v-if="dados.settings.ask_social_networks == true"
                                                                v-model="dados.settings.social_networks" 
                                                                item-text="value" 
                                                                item-value="id"
                                                                label="Rede Sociais *" 
                                                                placeholder="Rede Sociais *" 
                                                                background-color="white"
                                                                hide-details
                                                                outlined
                                                                multiple
                                                            />
                                                        </div>

                                                        <div class="col-6" v-if="dados.settings.ask_social_networks == true">
                                                            <v-textarea
                                                                :rules="[v => !!v || 'Campo Mensagem Perguntar Rede Sociais obrigatório']" 
                                                                v-model="dados.settings.ask_social_text" 
                                                                label="Mensagem Perguntar Rede Sociais *" 
                                                                placeholder="Mensagem Perguntar Rede Sociais *" 
                                                                background-color="white"
                                                                hide-details
                                                                outlined
                                                            ></v-textarea>
                                                        </div>

                                                        <div class="col-6" v-if="dados.settings.homologation">
                                                            <v-textarea
                                                                :rules="[v => !!v || 'Campo Números de homologação obrigatório']" 
                                                                v-model="dados.settings.numbers_homologation" 
                                                                label="Números de homologação *"  
                                                                placeholder="Números de homologação *"  
                                                                background-color="white"
                                                                readonly
                                                                hide-details
                                                                outlined
                                                                @click="openModalNumbers"
                                                            ></v-textarea>
                                                        </div>

                                                        <div class="col-6" v-if="dados.settings.ask_name">
                                                            <v-textarea
                                                                :rules="[v => !!v || 'Campo Mensagem Perguntar Nome obrigatório']" 
                                                                v-model="dados.settings.message_ask_name" 
                                                                label="Mensagem Perguntar Nome *" 
                                                                placeholder="Mensagem Perguntar Nome *" 
                                                                background-color="white"
                                                                hide-details
                                                                outlined
                                                            ></v-textarea>
                                                        </div>

                                                        <div class="col-6" v-if="dados.settings.confirm_name && dados.settings.ask_name">
                                                            <v-textarea
                                                                :rules="[v => !!v || 'Campo Mensagem Superior de Confirmar Nome obrigatório']" 
                                                                v-if="dados.settings.confirm_name && dados.settings.ask_name"
                                                                v-model="dados.settings.message_upper_confirm_name" 
                                                                label="Mensagem Superior de Confirmar Nome *" 
                                                                placeholder="Mensagem Superior de Confirmar Nome *" 
                                                                background-color="white"
                                                                hide-details
                                                                outlined
                                                            ></v-textarea>
                                                        </div>

                                                        <div class="col-6" v-if="dados.settings.confirm_name && dados.settings.ask_name">
                                                            <v-textarea
                                                                :rules="[v => !!v || 'Campo Mensagem Inferior de Confirmar Nome obrigatório']" 
                                                                v-model="dados.settings.message_down_confirm_name" 
                                                                label="Mensagem Inferior de Confirmar Nome *" 
                                                                placeholder="Mensagem Inferior de Confirmar Nome *" 
                                                                background-color="white"
                                                                hide-details
                                                                outlined
                                                            ></v-textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                        
                                                <div class="col-12">
                                                    <div class="row">
                                                        <div class="col-6">
                                                            <v-btn @click="closeDialog">
                                                                Cancelar
                                                            </v-btn>
                                                        </div>
                                                
                                                        <div class="col-6">
                                                            <div class="row justify-end">
                                                                <div class="col-12 col-md-3">
                                                                    <v-btn
                                                                        class="primary-button"
                                                                        @click="nextPage"
                                                                    >
                                                                        Próximo
                                                                    </v-btn>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </v-form>
                                    </v-stepper-content>
                                    <v-stepper-content step="2">
                                        <v-form ref="form_config_1">
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="row">
                                                        <div class="col-6">
                                                            <v-textarea
                                                                :rules="[v => !!v || 'Campo Mensagem Superior do Menu obrigatório']" 
                                                                v-model="dados.settings.message_upper_menu" 
                                                                label="Mensagem Superior do Menu - Nome Informado *"  
                                                                placeholder="Mensagem Superior do Menu *"  
                                                                background-color="white"
                                                                hide-details
                                                                outlined
                                                            ></v-textarea>
                                                        </div>

                                                        <div class="col-6">
                                                            <v-textarea
                                                                :rules="[v => !!v || 'Campo Mensagem Inferior do Menu obrigatório']" 
                                                                v-model="dados.settings.message_down_menu" 
                                                                label="Mensagem Inferior do Menu - Nome Informado *" 
                                                                placeholder="Mensagem Inferior do Menu *" 
                                                                background-color="white"
                                                                hide-details
                                                                outlined
                                                            ></v-textarea>
                                                        </div>

                                                        <div class="col-6">
                                                            <v-textarea
                                                                :rules="[v => !!v || 'Campo Mensagem Superior Após Erro do Menu obrigatório']" 
                                                                v-model="dados.settings.message_upper_option_invalidates_menu" 
                                                                label="Mensagem Superior Após Erro do Menu - Nome Informado *"  
                                                                placeholder="Mensagem Superior Após Erro do Menu *"  
                                                                background-color="white"
                                                                hide-details
                                                                outlined
                                                            ></v-textarea>
                                                        </div>

                                                        <div class="col-6">
                                                            <v-textarea
                                                                :rules="[v => !!v || 'Campo Mensagem Inferior Após Erro do Menu obrigatório']" 
                                                                v-model="dados.settings.message_down_option_invalidates_menu" 
                                                                label="Mensagem Inferior Após Erro do Menu - Nome Informado *" 
                                                                placeholder="Mensagem Inferior Após Erro do Menu *" 
                                                                background-color="white"
                                                                hide-details
                                                                outlined
                                                            ></v-textarea>
                                                        </div>

                                                        <div class="col-6">
                                                            <v-textarea
                                                                :rules="[v => !!v || 'Campo Texto Padrão de Despedida obrigatório']" 
                                                                v-model="dados.settings.goodbye_text_template" 
                                                                label="Texto Padrão de Despedida *" 
                                                                placeholder="Texto Padrão de Despedida *" 
                                                                background-color="white"
                                                                hide-details
                                                                outlined
                                                            ></v-textarea>
                                                        </div>

                                                        <div class="col-6">
                                                            <v-textarea
                                                                :rules="[v => !!v || 'Campo Mensagem de Horário de Atendimento Indisponível obrigatório']" 
                                                                v-model="dados.settings.message_offtime" 
                                                                label="Mensagem de Horário de Atendimento Indisponível *" 
                                                                placeholder="Mensagem de Horário de Atendimento Indisponível *" 
                                                                background-color="white"
                                                                hide-details
                                                                outlined
                                                            ></v-textarea>
                                                        </div>

                                                        <div class="col-6">
                                                            <v-textarea
                                                                :rules="[v => !!v || 'Campo Mensagem de Transfêrencia de Usuário obrigatório']" 
                                                                v-model="dados.settings.message_transfer" 
                                                                label="Mensagem de Transfêrencia de Usuário * - Nome Informado" 
                                                                placeholder="Mensagem de Transfêrencia de Usuário * - Nome Informado" 
                                                                background-color="white"
                                                                hide-details
                                                                outlined
                                                            ></v-textarea>
                                                        </div>

                                                        <div class="col-6">
                                                            <v-textarea
                                                                :rules="[v => !!v || 'Campo Mensagem de Transfêrencia de departamento obrigatório']" 
                                                                v-model="dados.settings.message_transfer_all_user_unavailable" 
                                                                label="Mensagem de Transfêrencia de departamento *" 
                                                                placeholder="Mensagem de Transfêrencia de departamento *" 
                                                                background-color="white"
                                                                hide-details
                                                                outlined
                                                            ></v-textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                        
                                                <div class="col-12">
                                                    <div class="row">
                                                        <div class="col-6">
                                                            <v-btn @click="closeDialog" style="margin-right: 10px">
                                                                Cancelar
                                                            </v-btn>
                                                            <v-btn @click="step = 1">
                                                                Voltar
                                                            </v-btn>
                                                        </div>
                                                
                                                        <div class="col-6">
                                                            <div class="row justify-end">
                                                                <div class="col-12 col-md-3">
                                                                    <v-btn
                                                                        class="primary-button"
                                                                        @click="nextPage"
                                                                    >
                                                                        Próximo
                                                                    </v-btn>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </v-form>
                                    </v-stepper-content>
                                    <v-stepper-content step="3">
                                        <v-form ref="form_config_2">
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="row">
                                                        <div class="col-6">
                                                            <v-textarea
                                                                v-model="dados.settings.message_call" 
                                                                label="Mensagem para Ligação"  
                                                                placeholder="Mensagem para Ligação"  
                                                                background-color="white"
                                                                hide-details
                                                                outlined
                                                            ></v-textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="row">
                                                        <div class="col-6">
                                                            <v-btn @click="closeDialog" style="margin-right: 10px">
                                                                Cancelar
                                                            </v-btn>
                                                            <v-btn @click="step = 2">
                                                                Voltar
                                                            </v-btn>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="row justify-end">
                                                                <div class="col-12 col-md-3">
                                                                    <v-btn class="primary-button" @click="nextPage">
                                                                        Próximo
                                                                    </v-btn>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </v-form>
                                    </v-stepper-content>
                                    <v-stepper-content step="4">
                                        <v-form ref="form_menus">
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="menu" v-for="(menu, i) in dados.menus" :key="i">
                                                        <div class="inputs">
                                                            <div class="row">
                                                                <div class="col-md-3 col-12">
                                                                    <v-select 
                                                                        :rules="[v => !!v || 'Campo Setor obrigatório']" 
                                                                        :items="departments" 
                                                                        v-model="dados.menus[i].department_id" 
                                                                        item-text="name" 
                                                                        item-value="id"
                                                                        label="Setor *" 
                                                                        placeholder="Setor *" 
                                                                        background-color="white"
                                                                        hide-details
                                                                        outlined
                                                                        @change="dados.menus[i].key = i + 1" 
                                                                    />
                                                                </div>
                                                                <div class="col-md-3 col-12">
                                                                    <v-select 
                                                                        :rules="[v => !!v || 'Campo Tipo do Menu obrigatório']" 
                                                                        :items="bot_menu_types" 
                                                                        v-model="dados.menus[i].bot_menu_type_id" 
                                                                        item-text="name" 
                                                                        item-value="id"
                                                                        label="Tipo do Menu *" 
                                                                        placeholder="Tipo do Menu *" 
                                                                        background-color="white"
                                                                        hide-details
                                                                        outlined
                                                                    />
                                                                </div>
                                                                <div class="col-md-3 col-12">
                                                                    <v-text-field 
                                                                        :rules="[v => !!v || 'Campo Nome do Menu obrigatório']" 
                                                                        v-model="dados.menus[i].text" 
                                                                        label="Nome do Menu *"  
                                                                        placeholder="Nome do Menu *"  
                                                                        hide-details
                                                                        outlined
                                                                    />
                                                                </div>
                                                                <div class="col-md-3 col-12">
                                                                    <v-text-field 
                                                                        :rules="[v => !!v || 'Campo Mensagem de Despedida obrigatório']" 
                                                                        v-model="dados.menus[i].goodbye_text" 
                                                                        label="Mensagem de Despedida *"  
                                                                        placeholder="Mensagem de Despedida *"  
                                                                        hide-details
                                                                        outlined
                                                                    />
                                                                </div>

                                                                <div class="col-12 row" v-if="[2,3,4].includes(dados.menus[i].bot_menu_type_id)">
                                                                    <div class="col-4">
                                                                        <v-text-field 
                                                                            :rules="[v => !!v || 'Campo Código da Agenda obrigatório']" 
                                                                            v-model="dados.menus[i].settings.tipo" 
                                                                            label="Código da Agenda*"  
                                                                            placeholder="Código da Agenda *"  
                                                                            hide-details
                                                                            outlined
                                                                        />
                                                                    </div>
                                                                    <div class="col-4">
                                                                        <v-text-field 
                                                                            :rules="[v => !!v || 'Campo Token da Empresa obrigatório']" 
                                                                            v-model="dados.menus[i].settings.token" 
                                                                            label="Token da Empresa*"  
                                                                            placeholder="Token da Empresa *"  
                                                                            hide-details
                                                                            outlined
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <v-btn v-if="dados.menus.length > 1 && !dados.menus[i].id" type="button" @click="removeItem(i)" color="primary" raised class="remover-menu">
                                                            <i class="fas fa-minus"></i>
                                                        </v-btn>
                                                        <v-container
                                                            v-else-if="dados.menus.length > 0"
                                                            style="max-width: 88px;"
                                                        >
                                                            <span>
                                                                Status
                                                            </span>
                                                            <v-switch
                                                                v-model="menu.status"
                                                                hide-details
                                                                style="padding-left: 12px;
                                                                    align-self: center;
                                                                    margin-top: 0"
                                                            ></v-switch>
                                                        </v-container>
                                                    </div>
                                                </div>

                                                <div class="col-12">
                                                    <div class="row justify-end">
                                                        <v-btn class="primary-button" style="margin-right: 12px" raised @click="addItem">
                                                            <i style="margin-right: 10px" class="fas fa-plus"></i> Adicionar Menu
                                                        </v-btn>
                                                    </div>
                                                </div>
                                        
                                                <div class="col-12">
                                                    <div class="row">
                                                        <div class="col-6">
                                                            <v-btn @click="closeDialog" style="margin-right: 10px">
                                                                Cancelar
                                                            </v-btn>
                                                            <v-btn @click="step = 3">
                                                                Voltar
                                                            </v-btn>
                                                        </div>
                                                
                                                        <div class="col-6">
                                                            <div class="row justify-end">
                                                                <div class="col-3">
                                                                    <v-btn
                                                                        style="margin-left: 20px;"
                                                                        class="primary-button"
                                                                        @click="nextPage"
                                                                    >
                                                                        Salvar
                                                                    </v-btn>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </v-form>
                                    </v-stepper-content>
                                </v-stepper-items>
                            </v-stepper>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-dialog v-model="dialog_numbers_homologation" persistent max-width="600px">
                <v-card>
                    <v-card-title>
                        <span class="headline"> <i class="fas fa-phone"></i> Números de Homologação </span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-form ref="form_telefones">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="menu" v-for="(numbers, i) in dados.settings.numbers_homologation" :key="i">
                                            <div class="inputs">
                                                <div class="row">
                                                    <div class="col-md-12 col-12">
                                                        <v-text-field 
                                                            :rules="[v => !!v || 'Campo Número obrigatório', v => v.length > 19 || 'Campo Número Incompleto']" 
                                                            v-model="dados.settings.numbers_homologation[i]" 
                                                            label="Número"  
                                                            placeholder="Número"  
                                                            v-mask="dados.settings.numbers_homologation[i] < 19 ? '+## (##) ####-####' : '+## (##) # ####-####'"
                                                            hide-details
                                                            outlined
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <v-btn type="button" @click="removeItemNumber(i)" color="primary" raised class="remover-menu">
                                                <i class="fas fa-minus"></i>
                                            </v-btn>
                                        </div>
                                    </div>

                                    <div class="col-12">
                                        <div class="row justify-end">
                                            <v-btn class="primary-button" style="margin-right: 12px" raised @click="addItemNumber">
                                                <i style="margin-right: 10px" class="fas fa-plus"></i> Adicionar Número
                                            </v-btn>
                                        </div>
                                    </div>
                                </div>
                            </v-form>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn class="primary-button" small @click="dialog_numbers_homologation = false">
                            Fechar
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn class="primary-button" small @click="numbersHomologationSave()">
                            Salvar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <DialogMensagem :visible="dialog_resposta" :mensagem="resposta" @close="dialog_resposta=false"/>
            <Loader v-if="loading"/>
        </div>
    </div>
</template>

<script>
	// importa o store
	import store from '@/store'
	// importa o componente de DialogMensagem 
	import DialogMensagem from '@/components/DialogMensagem.vue'
	// importa o componente de Loader 
	import Loader from '@/components/Loader.vue'
	// importa o componente de CustomTable 
	import CustomTable from '@/components/CustomTable.vue'

	// exporta o componente
	export default {
		// nome do componente
		// componentes filhos
		components: {
			// componente de DialogMensagem
			DialogMensagem,
			// componente de CustomTable
			CustomTable,
			// componente de Loader
			Loader,
		},
		// dados do componente
		data: () => ({
            boolean: [
                {
                    text: 'Sim',
                    value: true
                },
                {
                    text: 'Não',
                    value: false
                }
            ],
            step: 1,
			// nome do componente
			name: 'Bots',
			name_adicionar: 'Bot',
			empresas: [],
			// variável para mostrar a modal para editar/criar um menu
			dialog: false,
			// variável para mostrar a modal de informação
			dialog_resposta: false,
			// variável para a mensagem de resposta
			resposta: {},
			// variável para o loading
			loading: false,
            social_networks:[
                {
                    id:'Facebook',
                    value:'Facebook'
                },
                {
                    id:'Instagram',
                    value:'Instagram'
                },
                {
                    id:'Linkedin',
                    value:'Linkedin'
                },
                {
                    id:'Google / Internet',
                    value:'Google / Internet'
                },
                {
                    id:'Outro',
                    value:'Outro'
                }
            ],
			// variável para criar/editar menu
			dados: {
				id: '',
				name: '',
                settings: {
                    goodbye_text_template: '',
                    social_networks:[],
                    ask_social_networks:false,
                    ask_social_text:'',
                    ask_name: '',
                    confirm_name: '',
                    homologation: '',
                    message_ask_name: '',
                    message_down_confirm_name: '',
                    message_upper_confirm_name: '',
                    numbers_homologation: [],
                    message_down_menu: '',
                    message_upper_menu: '',
                    message_down_option_invalidates_menu: '',
                    message_upper_option_invalidates_menu: '',
                    message_call: '',
                    message_transfer: '',
                    message_transfer_all_user_unavailable: '',
                    message_offtime: '',
                },
                menus: [{
                    department_id: '',
                    bot_menu_type_id: '',
                    text: '',
                    goodbye_text: '',
                    key: '',
                    status: '',
                    settings: {
                        tipo:'',
                        token:''
                    }
                }]
			},
            dialog_numbers_homologation: false,
            bot_menu_types: [],
            api_types: [],
            departments: [],
			// variável para o cabeçalho da tabela
			headers: [
				{
					value: 'name',
					text: 'Nome',
					sortable: true,
				},
				{
					value: 'company.name',
					text: 'Empresa',
					sortable: true,
				},
				{
					value: 'acoes',
					sortable: false,
					text: 'Ações',
				},
			],
			// variável para os filtros da tabela
			filtros: {
				perPage: 20,
			},

			bots:[]
		}),
		// funções deste componente
		methods: {
            numbersHomologationSave(){
                // for(let i = 0; i < this.dados.settings.numbers_homologation.length; i++){
                //     this.dados.settings.numbers_homologation[i] = this.dados.settings.numbers_homologation[i].replace(/\D/g, '')
                // }
                if (this.$refs.form_telefones.validate()){

                    this.dialog_numbers_homologation = false
                }
            },
            openModalNumbers(){
                if(this.dados.settings.numbers_homologation == ''){
                    this.dados.settings.numbers_homologation = []
                }

                this.dialog_numbers_homologation = true
            },
			addItem(){
				this.dados.menus.unshift({
                    department_id: '',
                    bot_menu_type_id: '',
                    text: '',
                    goodbye_text: '',
                    key: '',
                    settings: {
                        tipo:'',
                        token:''
                    }
				})
			},
			removeItem(index){
				if (index > -1) {
					this.dados.menus.splice(index, 1);
				}
			},
			addItemNumber(){
				this.dados.settings.numbers_homologation.push('')
			},
			removeItemNumber(index){
				if (index > -1) {
					this.dados.settings.numbers_homologation.splice(index, 1);
				}
			},
            changePosition(arr, from, to) {
                arr.splice(to, 0, arr.splice(from, 1)[0]);
                return arr;
            },
            // async sortTable(index){
            //     if(!this.dados.menus[index].status) { this.dados.menus = this.changePosition(this.dados.menus, index, (this.dados.menus.length - 1)); }
            // },
            closeDialog(){
                this.dados = {
                    id: '',
                    name: '',
                    settings: {
                        goodbye_text_template: '',
                        ask_name: '',
                        confirm_name: '',
                        homologation: '',
                        message_ask_name: '',
                        message_down_confirm_name: '',
                        message_upper_confirm_name: '',
                        numbers_homologation: [],
                        message_down_menu: '',
                        message_upper_menu: '',
                        message_down_option_invalidates_menu: '',
                        message_upper_option_invalidates_menu: '',
                        message_call: '',
                        message_transfer: '',
                        message_transfer_all_user_unavailable: '',
                        message_offtime: '',
                    },
                    menus: [{
                        department_id: '',
                        bot_menu_type_id: '',
                        text: '',
                        goodbye_text: '',
                        key: '',
                        status: '',
                        settings: {}
                    }]
                }

                this.step = 1

                this.dialog = false
            },
            confirmName(){
                if(this.dados.settings.ask_name == false){
                    this.dados.settings.confirm_name = false 
                }
            },
            nextPage(){
                if(this.step == 1){

				    if (this.$refs.form_bot.validate()){

                        this.step = 2
                    }

                }else if(this.step == 2){

				    if (this.$refs.form_config_1.validate()){

                        this.step = 3
                    }

                }else if(this.step == 3){

				    if (this.$refs.form_config_2.validate()){

                        this.step = 4
                    }

                }else if(this.step == 4){

				    if (this.$refs.form_menus.validate()){

                        this.enviar()

                        this.step = 1
                    }

                }
            },
            async createBotDialog(){
                this.loading = true

                await this.getSelectMenutype()

                await this.getSelectDepartment()

                await this.getSelectApitype()

                this.dialog = true
                
                this.loading = false
            },
            async getSelectMenutype(){

                const resp = await store.dispatch('getMenutypeSelect')
                
				if(resp.status == 200){
					this.bot_menu_types = resp.data.bot_menu_types
				}
            },
            async getSelectApitype(){

                const resp = await store.dispatch('getApiTypeSelect')
                
				if(resp.status == 200){
					this.api_types = resp.data.api_types
				}
            },
			async getSelectDepartment(){
				// faz a requisição para o back para coletar os setores
				const resp = await store.dispatch('getSetoresSelect')
				// caso o status da resposta seja 200 (deu certo)
				if(resp.status == 200){
					// atribui a resposta na variavel departments
					this.departments = resp.data.departments
				}
			},
			// função para enviar um menu
			async enviar(){
				let resp = {}
                // coloca o componente como loading
                this.loading = await true

                if(this.dados.settings.numbers_homologation.length > 0 && typeof this.dados.settings.numbers_homologation[0] != 'number'){
                    for(let i = 0; i < this.dados.settings.numbers_homologation.length; i++){
                        this.dados.settings.numbers_homologation[i] = Number(this.dados.settings.numbers_homologation[i].replace(/\D/g, ''))
                    }
                }

                if(this.dados.id){
                    // coleta o status do setor
                    // dados.status = await this.usuario.status == 'Ativo' ? true : false

                    let date_update = await {
                        dados: this.dados,
                        id: this.dados.id
                    }

                    resp = await store.dispatch('putBot', date_update)
                }else{
                    // faz a requisição para o back para coletar os menus
                    resp = await store.dispatch('postBot', this.dados)
                }
                // caso o status da resposta seja 200 (deu certo)
                if(resp.status != 200 && resp.status != 201){
                    // atribui o título da mensagem 
                    this.resposta.titulo = await 'Algo deu errado!'
                    // atribui o corpo da mensagem 
                    this.resposta.mensagem = await  resp.data.message || resp.data.error
                    // mostra a mensagem
                    this.dialog_resposta = true
                // caso tenha dado algum erro
                }else{
                    // atribui o título da mensagem 
                    this.resposta.titulo = await this.name_adicionar + ' '  +  (this.dados.id ? 'editado!' : 'cadastrado!')
                    // atribui o corpo da mensagem 
                    this.resposta.mensagem = await this.name_adicionar + ' ' + (this.dados.id ? 'editado' : 'cadastrado') + ' com sucesso!'
                    // mostra a mensagem
                    this.closeDialog()
                    // fecha a modal de create/edit menu
                    this.dialog_resposta = true
                }
                // retira o loading do componente
                this.loading = false
                // atualiza a tabela
                this.$refs.tabela.init()
			},
			// função para coletar um setor para editar
			async editar(_id){
				// coloca o componente como loading
				this.loading = true
				// faz um dispatch para uma action no vuex store para pegar um setor passando o id 
				var resp = await store.dispatch('getBot', _id)
				// caso o status da resposta seja 200 (deu certo) 
				if(resp.status == 200){
					// atribui os dados vindos do back à váriável local
                    this.dados = {
                        id: resp.data.id,
                        name: resp.data.name,
                        settings: resp.data.settings,
                        menus: resp.data.menus,
                    }
					// mostra a modal de creat/edit dados
					this.createBotDialog()
				}else{
					// atribui o título da mensagem 
					this.resposta.titulo = await 'Algo deu errado!'
					// atribui o corpo da mensagem 
					this.resposta.mensagem = await  resp.data.message || resp.data.error
					// mostra a mensagem
					this.dialog_resposta = true
				}
				// retira o loading do componente
				this.loading = false
				// atualiza a tabela
				this.$refs.tabela.init()
			},
			// função que roda quando é fechada a modal de create/edit menu
			close(){
				// fecha a modal
				this.dialog = false

                this.dados = {
                    id: '',
                    name: '',
                    settings: {
                        ask_name: '',
                        confirm_name: '',
                        homologation: ''
                    },
                    horarios: [{
                        weekday: '',
                        start_time: '',
                        end_time: '',
                    }]
			    }
			},
		},
		// funções que rodam quando o componente é montado
		mounted(){
			// função de início do componente
			// this.init()
		},
	}
</script>

<style lang="scss">
	#bots{
		display: flex;
		max-height: 100%;
		padding: 24px;
		flex-wrap: wrap;
		.tabela-bot{
            width: 100%;
			background-color: #fff;
    		border-radius: 10px;
			.novo-bot{
				display: flex;
				justify-content: flex-end;
				padding-bottom: 0;
				button{
					i, svg{
						margin-right: 10px;
					}
					text-transform: capitalize;
				}
			}
		}
	}
    
	.menu{
		display: flex;
		flex: 1 1 auto;
		.inputs{
			display: flex;
			flex-wrap: wrap;
			flex: 1 1 auto;
		}
		.remover-menu{
			min-width: inherit;
			margin-top: 20px;
			margin-left: 24px;
		}
	}
</style>